import "./assets/styles/index.scss";
import "bootstrap/dist/css/bootstrap.css";
import React, {Component} from 'react';
import Router from "./app-router";

class App extends Component {
    render() {
        return (
            <Router/>
        );
    }
}

export default App;
