import React from 'react';
import {Spin, Space} from 'antd';
import {LoadingOutlined} from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

export default function LoadingIndicator({message}) {
    return (
        <div className="loading-indicator">
            <Space size="large">
                <Spin tip={message || 'Loading...'} size="large" indicator={antIcon}/>
            </Space>
        </div>
    );
}
