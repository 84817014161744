export const ENV = "dev"; // change to prod before commit.

export const API_BASE_URL_DEV = "https://api.occak.co";
export const API_BASE_URL_PROD = "http://65.109.228.54"
export const ACCESS_TOKEN = "access_token";

export const OAUTH2_REDIRECT_URI_DEV = 'https://occak.co/oauth2/redirect';
export const OAUTH2_REDIRECT_URI_PROD = 'https://spread-web-ui.herokuapp.com/oauth2/redirect';
// export const OAUTH2_REDIRECT_URI_PROD = 'http://localhost:3000/oauth2/redirect';

export const GOOGLE_AUTH_URL_DEV = API_BASE_URL_DEV + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI_DEV;
export const GOOGLE_AUTH_URL_PROD = API_BASE_URL_PROD + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI_PROD;
