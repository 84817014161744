import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import queryString from "query-string";
import {postOathCallback} from "../../util/ApiUtils";

// TODO
// http://localhost:3000/oauth2callback?code=4/0AfJohXlTD1_QLpZcliUbCYdN8LPTyDHWbdqFVMIvm_o3mwkt8frbGPNgTKtT_owyToSsHg&scope=https://www.googleapis.com/auth/drive.metadata.readonly%20https://www.googleapis.com/auth/spreadsheets
// After callback code should be parsed then application should redirect to created sheets screen.

const OathCallback = (props) => {
    const history = useHistory();
    useEffect(() => {
        const parsed = queryString.parse(props.location.search);
        if (!parsed.error && parsed.code) {
            postOathCallback(parsed.code).then(() => {
                history.push("/o/create-sheet");
            });
        } else {
            history.push("/error");
        }
    }, [])

    return (<></>);
}

export default OathCallback;
