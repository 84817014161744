import {useEffect, useState} from "react";
import constate from "constate";
import {getTemplates} from "../../util/ApiUtils";
import useAuth from "../auth/useAuth";

function useSheetsCreator() {
    const [templates, setTemplates] = useState();
    const {user} = useAuth();

    useEffect(() => {
        getTemplates().then(templates => {
            setTemplates(templates)
        }).catch(err => {
            console.error(err)
        })
    }, [user])

    return {
        templates, setTemplates
    }
}

const [SheetsProvider, useSheets] = constate(useSheetsCreator);
useSheets.Provider = SheetsProvider;
export default useSheets;
