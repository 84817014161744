import constate from "constate";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {getLandingTemplates} from "../../util/ApiUtils";

const themesFilterLabels = [
    {
        label: "Technology"
    },
    {
        label: "Portfolio"
    },
    {
        label: "E-Commerce"
    },
    {
        label: "Blog"
    },
    {
        label: "Resume"
    },
    {
        label: "Registration Form"
    },
    {
        label: "E-Commerce"
    },
    {
        label: "Blog"
    },
    {
        label: "Resume"
    },
    {
        label: "Registration Form"
    }
]


// dummy themes data
const themes = [{
    "templateId": "tuying",
    "name": "Template",
    "preview": "previewId=tuying",
    "thumbnail": "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*NLz5QayvKkkAAAAAAAAAAABkARQnAQ",
    "type": "read"
},
    {
        "templateId": "template",
        "name": "Template",
        "preview": "previewId=template",
        "thumbnail": "https://gw.alipayobjects.com/zos/rmsportal/zoItfYZBNMHPvqZDxuda.jpg",
        "type": "read"
    },
    {
        "templateId": "seeconf",
        "name": "Template",
        "preview": "previewId=seeconf",
        "thumbnail": "https://gw.alipayobjects.com/zos/rmsportal/GnDiHuTNxcXpSaJxhmRl.jpg",
        "type": "read"
    }, {
        "templateId": "template",
        "name": "Template",
        "preview": "previewId=template",
        "thumbnail": "https://gw.alipayobjects.com/zos/rmsportal/zoItfYZBNMHPvqZDxuda.jpg",
        "type": "read"
    },
    {
        "templateId": "seeconf",
        "name": "Template",
        "preview": "previewId=seeconf",
        "thumbnail": "https://gw.alipayobjects.com/zos/rmsportal/GnDiHuTNxcXpSaJxhmRl.jpg",
        "type": "read"
    },
    {
        "templateId": "template",
        "name": "Template",
        "preview": "previewId=template",
        "thumbnail": "https://gw.alipayobjects.com/zos/rmsportal/zoItfYZBNMHPvqZDxuda.jpg",
        "type": "read"
    },
    {
        "templateId": "seeconf",
        "name": "Template",
        "preview": "previewId=seeconf",
        "thumbnail": "https://gw.alipayobjects.com/zos/rmsportal/GnDiHuTNxcXpSaJxhmRl.jpg",
        "type": "read"
    }]

function useThemesContextState() {
    const [previewThemes, setPreviewThemes] = useState(null);
    const [allThemes, setAllThemes] = useState(null);
    const [filterLabels, setFilterLabels] = useState(null);

    useEffect(()=>{
        getLandingTemplates().then(templates=>{
            if(!templates){
                return;
            }
            setAllThemes(templates)
            setPreviewThemes(templates.slice(0, 3));
            setFilterLabels(themesFilterLabels)
        })
    },[]);

    return {
        previewThemes,
        allThemes,
        filterLabels,
        setAllThemes
    }
}

useThemesContextState.propTypes = {
    allThemes: PropTypes.object,
    previewThemes: PropTypes.object,
    filterLabels: PropTypes.object
}

const [ThemesContextProvider, useThemesContext] = constate(useThemesContextState);
useThemesContext.Provider = ThemesContextProvider;
export {useThemesContext};
