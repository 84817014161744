import {useEffect, useState} from "react";
import constate from "constate";
import {ACCESS_TOKEN} from "../../constants";
import {getCurrentUser} from "../../util/ApiUtils";
import {useHistory} from "react-router-dom";

function useAuthCreator() {
    const history = useHistory();
    const [token, setToken] = useState();
    const [user, setUser] = useState();

    const storeToken = (token) => {
        if (!token) {
            setUser(null);
            localStorage.clear();
            setToken(null)
        } else {
            setToken(token);
            localStorage.setItem(ACCESS_TOKEN, token);
        }
    }

    const getUser = () => {
        getCurrentUser().then(res => {
            setUser(res);
        }).catch(error => {
            console.error(error)
        })
    }

    useEffect(() => {
        const localToken = localStorage.getItem(ACCESS_TOKEN);
        if (!localToken) {
            return;
        }
        storeToken(localToken);
        getCurrentUser().then(res => {
            setUser(res);
        }).catch(error => {
            history.push('/')
            console.error(error)
        })
    }, [history, token])

    return {
        token, storeToken,
        user, setUser,
        history,
        getUser
    }
}

const [AuthProvider, useAuth] = constate(useAuthCreator);
useAuth.Provider = AuthProvider;
export default useAuth;
