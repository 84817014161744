import React, {useEffect, useState} from "react";
import useAuth from "./useAuth";
import queryString from "query-string";
import {useHistory} from "react-router-dom";
import LoadingIndicator from "../../components/loader/loader";

const OathHandler = (props) => {
    const history = useHistory();
    const {token, storeToken, user} = useAuth();

    useEffect(() => {
        const parsed = queryString.parse(props.location.search);
        if (!parsed.error) {
            storeToken(parsed.token);
        } else {
            history.push("/error");
        }
    }, [props.location.search, storeToken])

    if (token && user) {
        history.push("/o/sheets");
    }
    return (<LoadingIndicator/>);
}

export default OathHandler;

