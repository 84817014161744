import {ACCESS_TOKEN} from '../constants';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options).then(response => {
            return response.json().then(async json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        }
    );
};

export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }
    return request({
        url: API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function postOathCallback(code) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }
    return new Promise((resolve, reject) => {
        try {
            localStorage.getItem(ACCESS_TOKEN) ?
                resolve(request({
                    url: API_BASE_URL + `/oauth2callback?code=${code}`,
                    method: 'GET'
                })) :
                reject("No access token set")
        } catch (e) {
            throw(e);
        }
    })
}

export function deleteSheet(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const options = {
                url: API_BASE_URL + "/remove/" + id,
                method: 'POST'
            }
            const resp = await request(options)
            if (resp) {
                resolve(resp)
            }
        } catch (e) {
            reject(e)
        }
    })
}

export function getTemplates() {
    return new Promise((resolve, reject) => {
        try {
            localStorage.getItem(ACCESS_TOKEN) ?
                resolve(request({
                    url: API_BASE_URL + "/getAllTemplates",
                    method: 'GET'
                })) :
                reject("No access token set")
        } catch (e) {
            throw(e);
        }
    })
}

export function getLandingTemplates() {
    return new Promise((resolve, reject) => {
        try {
            resolve(request({
                url: API_BASE_URL + "/op/getAllTemplates",
                method: 'GET'
            }))
        } catch (e) {
            throw(e);
        }
    })
}

export async function getResultPageWithId(pageCode) {
    const options = {
        url: API_BASE_URL + "/op/getResultPage/" + pageCode,
        method: 'GET'
    }
    return await request(options);
}

export function sheetRequest() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }
    return request({
        url: API_BASE_URL + "/api/sheet",
        method: 'GET'
    });
}


export function createSpreadSheet(spreadsheetRequest) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set");
    }
    return request({
        url: API_BASE_URL + "/api/createSpreadsheet",
        method: 'POST',
        body: JSON.stringify(spreadsheetRequest)
    })
}

export function oath2callback(code) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set");
    }
    return request({
        url: code,
        method: 'GET',
    })
}

export function checkCredentials(code) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set");
    }
    return request({
        url: API_BASE_URL + "/api/check",
        method: 'GET',
    })
}

export function createToken(code) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set");
    }

    return request({
        url: API_BASE_URL + "/oauth2callback?code=" + code,
        metdod: 'GET'
    })
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}


