import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import OathHandler from "./state/auth/oath-handler";
import useAuth from "./state/auth/useAuth";
import {useThemesContext} from './state/themes'
import useSheets from "./state/sheets/sheets";
import OathCallback from "./state/auth/oath-callback";

const LandingLayout = lazy(() => import("./views/landing-layout"));
const SpreadWebLayout = lazy(() => import("./views/spreadweb-layout"))
const TemplatesLayout = lazy(() => import("./views/template-viewer-layout"))

const Router = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading... </div>}>
                <useAuth.Provider>
                    <useSheets.Provider>
                        <Switch>
                            <Route path="/oauth2/redirect" render={(props) => <OathHandler {...props}/>}/>
                            <Route path="/oauth2callback" render={(props) => <OathCallback {...props}/>}/>
                            <Route path="/op" component={SpreadWebLayout}/>
                            <useThemesContext.Provider>
                                <Switch>
                                    <Route path="/template" render={(props)=> <TemplatesLayout {...props}/>}/>
                                    <Route path="/" component={LandingLayout}/>
                                </Switch>
                            </useThemesContext.Provider>
                        </Switch>
                    </useSheets.Provider>
                </useAuth.Provider>
            </Suspense>
        </BrowserRouter>
    )
}

export default Router;
